/**
 * 扁平化变为树结构
 * @param list
 * @param id
 * @param parent_id
 */
 function buildTree(list, id, parent_id) {
    if (typeof id == 'undefined') {
        id = 'uuid';
    }

    if (typeof parent_id == "undefined") {
        parent_id = 'parent_uuid';
    }

    let temp = {};
    let tree = [];
    for (let i in list) {
        temp[list[i][id]] = list[i];
    }
    for (let i in temp) {
        if (temp[i][parent_id]) {
            if (!temp[temp[i][parent_id]].children) {
                temp[temp[i][parent_id]].children = new Array();
            }
            temp[temp[i][parent_id]].children.push(temp[i]);
        } else {
            tree.push(temp[i]);
        }
    }
    return tree;
}


function toTree(data, parent_uuid = '') {
    let tree = [];
    let temp;
    for (let i = 0; i < data.length; i++) {
        if (data[i].parent_uuid == parent_uuid) {
            let obj = data[i];
            temp = toTree(data, data[i].uuid);
            if (temp.length > 0) {
                obj.children = temp;
            }
            tree.push(obj);
        }
    }
    return tree;
}


function number_format(number, decimals, dec_point, thousands_sep) {
    /*
     * 参数说明：
     * number：要格式化的数字
     * decimals：保留几位小数
     * dec_point：小数点符号
     * thousands_sep：千分位符号
     * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/**
 * 获取页面的最大z-index的值
 * @return {[type]} [description]
 */
function getMaxZIndex() {
    let arr = [...document.all].map(e => +window.getComputedStyle(e).zIndex || 0);
    return arr.length ? Math.max(...arr) + 1 : 0
}


/**
 * 字符串转日期格式，strDate要转为日期格式的字符串
 * alert(getDate("2016-06-14"));
 * @param  {[type]} strDate [description]
 * @return {[type]}         [description]
 */
function getDate(strDate) {
    let date = new Date(strDate);
    return date;
}

function dateFormat(date,fmt = 'yyyy-MM-dd') {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}



/**
 * 日期根式转换为字符串
 * @param  {[type]} d   [description]
 * @param  {String} fmd [description]
 * @return {[type]}     [description]
 */
function dateString(d, fmd = 'yyyy-MM-dd') {

    Date.prototype.pattern = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份         
            "d+": this.getDate(), //日         
            "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时         
            "H+": this.getHours(), //小时         
            "m+": this.getMinutes(), //分         
            "s+": this.getSeconds(), //秒         
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度         
            "S": this.getMilliseconds() //毫秒         
        };

        var week = {
            "0": "/u65e5",
            "1": "/u4e00",
            "2": "/u4e8c",
            "3": "/u4e09",
            "4": "/u56db",
            "5": "/u4e94",
            "6": "/u516d"
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        if (/(E+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" :
                "/u5468") : "") + week[this.getDay() + ""]);
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    }
    let date = new Date(d);
    return date.pattern(fmd);
}




/**
 * 字符串转日期格式，strDate要转为日期格式的字符串
 * 测试 
 * alert(getDate("2016-6-14 11:20:00")); 
 * @param  {[type]} strDate [description]
 * @return {[type]}         [description]
 */
function getDateTime(strDate) {
    var st = strDate;
    var a = st.split(" ");
    var b = a[0].split("-");
    var c = a[1].split(":");
    var date = new Date(b[0], b[1], b[2], c[0], c[1], c[2]);
    return date;
}

/**
 * 虚拟加载的缓存处理数据
 * @param  Array  data  []
 * @param  Int    page_num = 1
 * @param  Int    page_len = 10
 * @return Array
 */
function dataSlice(data, page_num = 1, page_len = 10) {
    let start = page_num - 1;
    if (start <= 0) {
        start = 0;
    }
    start *= page_len; // 切割开始位置

    if (data.length <= start) {
        return [];
    } else {
        return data.slice(start, page_len);
    }
}

/**
 * 检测接口是否为授权接口
 * @param  {[type]} api_module [description]
 * @param  {Array}  menus      [description]
 * @param  {[type]} login_info [description]
 * @param  {String} api_ver    [description]
 * @return {[type]}            [description]
 */
function checkLevelMenu(api_module, menus = [], api_ver = "1.0.0") {
    let menu = {};
    for (let i = 0; i < menus.length; i++) {
        if (!menus[i].api_module && !menus[i].api_ver) {
            if (api_module == menus[i] && api_ver == menus[i].api_ver) {
                return true;
            }
        }
    }
    return false;
}

/**
 * 获取存储数据
 * @param {Object} key
 * @return mixed
 */
function storage_get(key, is_timeout = true) {
    let _data = localStorage.getItem(key);
    if (!_data || _data == '' || _data == null || typeof _data == undefined) {
        return null;
    }

    _data = JSON.parse(_data);

    // 是否有时间限制 true:有时间限制,false:无时间限制
    if (is_timeout == true) {
        if (_data.timeout < get_timestamp()) {
            return null;
        }
    }


    return _data.data;
}

/**
 * 存储数据
 */
function storage_set(key, value, timeout = 7200) {
    if (timeout <= 0) {
        timeout = 0;
    }

    let _data = {
        timeout: get_timestamp() + timeout,
        data: value
    }
    localStorage.setItem(key, JSON.stringify(_data));
}

/**
 * 获取存储数据
 * @param {Object} key
 * @return mixed
 */
 function session_get(key) {
    let _data = sessionStorage.getItem(key);
    if (!_data || _data == '' || _data == null || typeof _data == undefined) {
        return null;
    }

    _data = JSON.parse(_data);
    return _data.data;
}

/**
 * 存储数据
 */
function session_set(key, value = null) {
    if (value != null) {
        let _data = {
            data: value
        }
        sessionStorage.setItem(key, JSON.stringify(_data));
    } else {
        sessionStorage.removeItem(key);
    }
    
}

/**
 * 获取时间戳,单位秒
 */
function get_timestamp() {
    return parseInt(Date.parse(new Date()) / 1000);
}



function img_url(img_url, host = '') {
    if (img_url.indexOf("http") == 0) {
        return img_url;
    }

    return host + img_url;
}

// 生成uuid
function uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        let r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}


/**
 * 判断变量值是否为空 undefined null 都是为空
 * @param  {[type]} val [description]
 * @return {[type]}     [description]
 */
 function empty(val) {
    if (typeof val == 'undefined' || val == null) {
        return true;
    }
    return false;
}


function valid_err(errors) {
    let err = 0;
    for (let key in errors) {
        let item = errors[key];

        if (item && item.length) {
            err++;
        }
    }

    return err == 0 ? false : true;
}

function has_api(api_list, api, ver = '1.0.0') {
    if (empty(api_list[api + '-' + ver])) {
        return false;
    } else {
        return true;
    }
}

function rand(len = 4){
    let str = '';
    for(let i = 0; i < len; i++) {
        let index = parseInt(Math.random() * 10);
        str += index;
    }
    return str;
}


function base64_encode(val){
    let Base64 = require('js-base64').Base64;
    return Base64.encode(val);
}

function base64_decode(val){
    let Base64 = require('js-base64').Base64;
    return Base64.decode(val);
}

function is_mobile() {
    var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
    var u = navigator.userAgent;
    if (null == u) {
        return true;
    }
    var result = regex_match.exec(u);

    if (null == result) {
        return false
    } else {
        return true
    }
}

/**
 * 域名和标题
 */
function getIPC(list, pkey) {
    let domainName = window.location.hostname;
    let value = null;
    let keys = Object.keys(list);
    keys.forEach(key => {
        if (domainName.indexOf(key) >= 0){
            value = list[key][pkey];
        }
    })
    if (empty(value)) {
        return domainName;
    } else {
        return value;
    }
}


export default {
    buildTree,
    toTree,

    number_format,

    getMaxZIndex,

    getDate,
    getDateTime,
    dateString,
    dateFormat,

    dataSlice,
    checkLevelMenu,

    storage_get,
    storage_set,
    session_get,
    session_set,

    get_timestamp,

    img_url,

    uuid,
    empty,
    valid_err,

    has_api,

    rand,

    base64_decode,
    base64_encode,

    is_mobile,

    getIPC
}
