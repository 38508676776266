import Vue from 'vue'
import VueRouter from 'vue-router'
import lime from "@/lime.js";
import util from "@/util.js";
import store from "@/store";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const suffix = ' - ' +  util.getIPC(store.state.InitData.host_ipc, 'title');

const routes = [
    // 首页页面
    {
        path: '/',
        component: () => import('../views/Index.vue'),
        meta: {
            rbac: 'frees',
            title: '首页' + suffix
        }
    },

    

    
    {
        path: '/news/:news_type_uuid?',
        component: () => import('../views/News/index.vue'),
        meta: {
            rbac: 'frees',
            title: '新闻' + suffix
        }
    },

    {
        path: '/news/detail/:news_uuid',
        component: () => import('../views/News/detail.vue'),
        meta: {
            rbac: 'frees',
            title: '新闻详细'  + suffix
        }
    },

    {
        path: '/business',
        component: () => import('../views/ShopType/index.vue'),
        meta: {
            rbac: 'frees',
            title: '业务列表'  + suffix
        }
    },

    {
        path: '/business/detail/:shop_type_uuid',
        component: () => import('../views/ShopType/detail.vue'),
        meta: {
            rbac: 'frees',
            title: '业务详细'  + suffix
        }
    },



    
    {
        path: '/spage/:spage_uuid',
        component: () => import('../views/Spage/index.vue'),
        meta: {
            rbac: 'frees',
            title: '单页详细'  + suffix
        }
    },

    {
        path: '/login',
        component: () => import('../views/Login/index.vue'),
        meta: {
            rbac: 'frees',
            title: '登录'  + suffix
        }
    },

    {
        path: '/miss_pwd',
        component: () => import('../views/Login/miss_pwd.vue'),
        meta: {
            rbac: 'frees',
            title: '忘记密码'  + suffix
        }
    },

    {
        path: '/reg',
        component: () => import('../views/Login/reg.vue'),
        meta: {
            rbac: 'frees',
            title: '注册'  + suffix
        }
    },

    {
        path: '/member',
        component: () => import('../views/Member/index.vue'),
        meta: {
            rbac: 'logins',
            title: '会员中心' + suffix
        },
        children: [
            {
                path: '/member/',
                component: () => import('../views/Member/index.vue'),
                meta: {
                    rbac: 'logins',
                    title: '会员信息',
                    icon:''
                }
            },
            {
                path: '/member/reset_pwd',
                component: () => import('../views/Member/reset_pwd.vue'),
                meta: {
                    rbac: 'logins',
                    title: '修改密码',
                    icon:''
                }
            },
            {
                path: '/member/xunjia',
                component: () => import('../views/Member/Xunjia/index.vue'),
                meta: {
                    rbac: 'logins',
                    title: '询价管理',
                    icon:''
                }
            },
            {
                path: '/member/liuyan',
                component: () => import('../views/Member/Liuyan/index.vue'),
                meta: {
                    rbac: 'logins',
                    title: '留言管理',
                    icon:''
                }
            },

            {
                path: '/member/pinglun',
                component: () => import('../views/Member/NewsCommit/index.vue'),
                meta: {
                    rbac: 'logins',
                    title: '新闻评论',
                    icon:''
                }
            },

        ]
    }
];

const router = new VueRouter({
    // mode: 'history', // 去除#号
    // base: process.env.BASE_URL,
    routes
});


// 路由守护处理,登录非登录的界面
router.beforeEach((to, from, next) => {
    let login_token = lime.cookie_get('login_token');

    if (lime.debug) {
        console.log(login_token);
    }

    if (to.meta.rbac != 'frees') {
        if (util.empty(login_token)) {
            lime.cookie_set('login_token', null, 0);
            next('/login');
        } else {
            // 检测login_token是否在线
            lime.req('CheckLoginToken', {
                login_token: login_token,
                business_type: 'member',
                login_type: 0
            }).then(res => {
                // 更新cookie的缓存,2小时
                lime.cookie_set('login_token', login_token, 7200);
                next();
            }).catch(err => {
                lime.cookie_set('login_token', null, 0);
                // 检测失败,直接跳转到登录页面
                next('/login');
            })
        }
    } else {
        next();
    }
})

export default router

