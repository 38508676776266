import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        InitData: {
            _ver_:'1.0.8',
            login_view:{
                login_head_img:require('@/assets/imgs/aimeipin.fw.png'),
                login_main_img:require('@/assets/imgs/zhutu.fw.png'),
                login_bg_color:'#070709',
                login_form_title:'运营端登录',
                login_ipc:'皖ICP备13005140号',
                login_comp_name:'测试公司名称'
            },
            token_num:0,

            host_ipc:{
                // 百度的域名
                'yingwujiao.com':{ipc:'皖ICP备2022007162号-1', title:'数字生产'},
                'yingwujiao.cn':{ipc:'皖ICP备2022007162号-5', title:'鹦鹉角'},
                '5zhoubian.com':{ipc:'皖ICP备2022007162号-4', title:'我周边'},

                // 阿里云的域名
                '3yphp.com':{ipc:'皖ICP备13005140号-5', title:'3yphp框架介绍'},
                '5zhoubian.cn':{ipc:'皖ICP备13005140号-4', ttile:'我的开发测试3y'},
                '50yuan.cn':{ipc:'皖ICP备13005140号-3', title:'我的开发测试3y'},
                'aimeipin.cn':{ipc:'皖ICP备13005140号-1', title:'代码开发接口测试'},
                
                // 本地的展示
                '127.0.0.1':{ipc:'本地无IPC', title:'本地测试标题后缀'},
                'localhost':{ipc:'本地无IPC', title:'本地测试标题后缀'},
            }
        },
        WsData:{
            ws:null,
            status: 0,
            accept_list:[], // 接收的列表消息清单

            // 模块更新的清单处理
            update_list:{   
                task_bi:0,  // 看板模块更新
                work_day:0, // 工作日清模块更新
                notice:0,   // 消息通知的更新

            },
            dialog_cont:'',
            dialog_type:'info',

            open:true
        }
    },
    mutations: {
        setTokenNum(state, val) {
            state.InitData.token_num = val;
        },
        setLoginView(state, data) {
            state.InitData.login_view = data;
        },

        ws_init(state, obj) {
            state.WsData.ws = obj;
        },

        // 更新的状态值
        ws_status(state, val) {
            state.WsData.status = val;
        },

        // 客户端发送的消息
        ws_send(state, obj) {
            state.WsData.send_list.push(obj);
            let len = state.WsData.send_list.length - 300;
            if (len > 0) {
                state.WsData.send_list.splice(0, len);
            }
        },

        // 客户端接收的消息
        ws_accept(state, obj){
            state.WsData.accept_list.push(obj);
            let len = state.WsData.accept_list.length - 300;
            if (len > 0) {
                state.WsData.accept_list.splice(0, len);
            }
        },
        // 消息更新通知
        ws_dialog(state, obj){
            state.WsData.dialog_cont = obj.content;
            state.WsData.dialog_type = obj.type;
        },

        // 是否开启websocket
        ws_open(state, val) {
            state.WsData.open = val;
        },

        // 模块数据更改的通知更新
        ws_update(state, obj) {
            state.WsData.update_list[obj.module] = obj.update_time;
        }
    },
    actions: {},
    modules: {}
})
